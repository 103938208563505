import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore as useUserStore } from "~/stores/userStore";

export default async () => {
  const store = useUserStore();

  onMounted(() => {
    store.startRefreshingToken();
  });

  await store.init();

  const refs = storeToRefs(store);

  return {
    getLoginUrl: store.getLoginUrl,
    getLogoutUrl: store.getLogoutUrl,
    isLoggedIn: refs.isLoggedIn,
    teamName: refs.teamName,
    teamId: refs.teamId,
    user: refs.user,
    roles: refs.roles,
  };
};
