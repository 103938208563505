<template>
  <UserAvatar v-bind="$attrs" :user="user">
    <q-menu v-if="hasMenu">
      <q-list style="min-width: 250px">
        <q-item v-if="user.name">
          <q-item-section avatar>
            <UserAvatar v-bind="$attrs" :user="user" />
          </q-item-section>
          <q-item-section>
            <q-item-label style="margin-bottom: -2px">{{
              user.name
            }}</q-item-label>
            <q-item-label caption class="q-mt-xs">
              <span class="column gap-xs" style="gap: 2px">
                <span class="ellipsis" style="max-width: 300px">{{
                  user.email
                }}</span>
                <span class="ellipsis" style="max-width: 300px">{{
                  teamName
                }}</span>
              </span>
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-separator />
        <q-item v-close-popup clickable to="/settings">
          <q-item-section side><q-icon name="mdi-cog" /> </q-item-section>
          <q-item-section class="text-capitalize">
            {{ $t("user.settings") }}
          </q-item-section>
        </q-item>
        <q-item
          v-close-popup
          clickable
          data-testId="logoutButton"
          :href="getLogoutUrl()"
        >
          <q-item-section side><q-icon name="mdi-logout" /> </q-item-section>
          <q-item-section class="text-capitalize text-no-wrap">{{
            $t("user.sign_out")
          }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>

    <slot />
  </UserAvatar>
</template>

<script lang="ts" setup>
import useAuth from "~/composables/useAuth";

import { UserAvatar } from "#components";
import { computed } from "vue";

import type { UserInformation } from "~/src/models/User.model";

const { getLogoutUrl, user: userData, teamName } = await useAuth();

const user = computed<UserInformation>(() => ({
  email: userData.value?.email ?? "",
  name: userData.value?.name ?? "",
  id: userData.value?.keycloakId ?? "",
}));

defineProps<{
  hasMenu?: boolean;
}>();
</script>
