<template>
  <q-item
    :key="link.title"
    :active-class="
      $q.dark.isActive ? 'bg-grey-9 text-white' : 'bg-grey-3 text-black'
    "
    :to="
      link.alert || !link.linkName
        ? undefined
        : { path: link.linkName, force: true }
    "
    class="q-pa-none select-none"
    clickable
    tag="router-link"
    @click="linkClicked(link)"
  >
    <slot />
    <q-menu
      v-if="link.alert"
      v-model="isAlertOpen"
      anchor="center end"
      self="center start"
      :offset="[10, 0]"
    >
      <Component
        :is="link.alertComponent"
        @click:confirm="goToLink(link)"
        @click:cancel="cancelClicked()"
      />
    </q-menu>
    <q-item-section
      v-if="link.icon"
      avatar
      class="column items-center justify-center full-width q-pa-sm"
    >
      <q-icon :name="link.icon" />
      <div class="row items-center justify-center text-center q-mt-xs">
        <span class="miniText">{{ link.title }}</span>
      </div>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import { navigateTo } from "#imports";
import { ref } from "vue";

export type RailLink = {
  title: string;
  icon: string;
  linkName?: string;
  alert?: boolean;
  alertComponent?: unknown;
  canGoTo?: () => boolean;
  isHardlyUsed?: boolean;
  callback?: () => unknown;
};

defineProps<{ link: RailLink }>();

const isAlertOpen = ref(false);

const goToLink = (link: RailLink) =>
  navigateTo({ name: link.linkName, force: true });

const cancelClicked = () => (isAlertOpen.value = false);

const linkClicked = (link: RailLink) => {
  if (isAlertOpen.value) goToLink(link);
  if (link.callback) link.callback();
};
</script>

<style lang="scss">
.miniText {
  font-size: 10px;
  user-select: none;
  text-transform: capitalize;
}
</style>
