import { useCaseFilters } from "~/composables/useCaseFilters";
import type { RailLink } from "~/components/Layout/LayoutRailLink.vue";
import { useUserStore } from "~/stores/userStore";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export const useRailLinks = () => {
  const { t } = useI18n();
  const store = useUserStore();
  const { resetRequest } = useCaseFilters();

  const getLinksList = (): RailLink[] => [
    {
      title: t("feature.cases"),
      icon: "mdi-clipboard-check-multiple",
      linkName: "/cases",
      callback: () => resetRequest(),
      canGoTo: () => store.isLoggedIn && store.hasPermission.Case.read,
    },
    {
      title: t("feature.email_templates"),
      icon: "mdi-email",
      linkName: "/email-templates",
      canGoTo: () => store.isLoggedIn && store.hasPermission.Emailtemplate.read,
      isHardlyUsed: true,
    },
    {
      title: "Policies",
      icon: "mdi-ballot",
      linkName: "/policies",
      canGoTo: () => store.isLoggedIn && store.hasPermission.Policy.read,
      isHardlyUsed: true,
    },
    {
      title: "Libraries",
      icon: "mdi-book-open",
      linkName: "/libraries",
      canGoTo: () => store.isLoggedIn && store.hasPermission.Library.read,
    },
    {
      title: "Service Hooks",
      icon: "mdi-hook",
      linkName: "/service-hooks",
      canGoTo: () =>
        store.isLoggedIn && store.hasPermission["Service-hook"].read,
      isHardlyUsed: true,
    },
    {
      title: "Template Groups",
      icon: "mdi-vector-triangle",
      linkName: "/template-groups",
      canGoTo: () => store.isLoggedIn && store.hasPermission["Template"].read,
    },
    {
      title: "Data Blocks",
      icon: "mdi-data-matrix",
      linkName: "/data-blocks",
      canGoTo: () => store.isLoggedIn && store.hasPermission["Template"].read,
    },
    {
      title: "Inspections",
      icon: "mdi-camera-document",
      linkName: "/inspections/surveyors",
      canGoTo: () =>
        store.isLoggedIn &&
        store.hasPermission["containerinspectioncompany"].read,
    },
    {
      title: t("feature.verified_shippers"),
      icon: "mdi-ferry",
      linkName: "/verified-shippers",
      canGoTo: () =>
        store.isLoggedIn && store.hasPermission["globalverifiedshipper"].search,
    },
  ];

  const allVisibleLinks = computed(() => {
    return getLinksList().filter((link) => {
      if (link.canGoTo) return link.canGoTo();
      return true;
    });
  });

  const sideBarLinks = computed(() => {
    return allVisibleLinks.value.filter(
      (x) => x.isHardlyUsed === undefined || x.isHardlyUsed === false
    );
  });
  const extraLinks = computed(() => {
    return allVisibleLinks.value.filter((x) => x.isHardlyUsed === true);
  });

  return {
    allVisibleLinks,
    sideBarLinks,
    extraLinks,
  };
};
