<template>
  <q-layout view="lHh Lpr lFf">
    <LayoutHeader @click:toggle-drawer="toggleLeftDrawer" />
    <LayoutDrawer
      :left-drawer-open="leftDrawerOpen"
      @click:toggle-drawer="toggleLeftDrawer"
    />
    <q-page-container class="container-xl" style="padding-top: 50px">
      <NuxtErrorBoundary @error="console.error">
        <q-page padding class="column">
          <LazyNuxtPage />
        </q-page>
        <template #error="{ error, clearError: wipeError }">
          <q-page padding class="bg-red text-white full-width">
            <div class="column items-start gap-sm">
              <h1 class="text-white">Oops! Something went wrong</h1>
              <div class="flex gap-sm">
                <q-btn
                  label="try again"
                  outline
                  icon="mdi-reload"
                  @click="wipeError"
                />
                <q-btn
                  label="Go Home"
                  outline
                  icon="mdi-home"
                  @click="goHome(wipeError)"
                />
              </div>
              <code>{{ error }}</code>
            </div>
          </q-page>
        </template>
      </NuxtErrorBoundary>
      <div
        id="sticky-spot"
        class="q-layout-padding"
        style="position: sticky; padding-bottom: 0; padding-top: 0; bottom: 0"
      />
    </q-page-container>
  </q-layout>
</template>
<script lang="ts" setup>
import { QLayout, QPageContainer, useQuasar } from "quasar";
import { ref } from "vue";
import { navigateTo, useHead } from "#imports";

const $q = useQuasar();
const leftDrawerOpen = ref(true);

const goHome = async (wipeError: () => unknown) => {
  await navigateTo("/");
  await wipeError();
};

const toggleLeftDrawer = (e?: boolean) => {
  if (e !== undefined) {
    leftDrawerOpen.value = e;
    return;
  }

  if ($q.screen.lt.md) {
    leftDrawerOpen.value = !leftDrawerOpen.value;
  } else {
    leftDrawerOpen.value = true;
  }
};

useHead({
  titleTemplate: (x) => (x ? `Hazcheck Detect | ${x}` : "Hazcheck Detect"),
});
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>
